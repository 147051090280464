import React from 'react';
import { useSelector } from 'react-redux';

import * as MODALS from 'common_constants/modals';

import HistoryHotlines from './HistoryHotlines';
import HotlineMeet from './HotlineMeet';
import AddPartnersHotline from './AddPartnersHotline';
import CourtPractice from './CourtPractice';
import NewContract from './NewContract';

import './style.scss';

const Modals = () => {
  const name = useSelector((state) => state.common.modal?.name);

  switch (name) {
    case MODALS.HISTORY_HOTLINES:
      return <HistoryHotlines />;
    case MODALS.HOTLINE_MEET_MODAL:
      return <HotlineMeet />;
    case MODALS.ADD_PARTNERS_HOTLINE:
      return <AddPartnersHotline />;
    case MODALS.COURT_PRACTICE:
      return <CourtPractice />;
    case MODALS.NEW_CONTRACT:
      return <NewContract />;
    default:
      return null;
  }
};

export default Modals;
