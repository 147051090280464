import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spin, Modal, Input, DatePicker, Typography, Divider, Popover, Alert } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import locale from 'antd/locale/uk_UA';

import { setModal, setCurrentClient, selectUsers, setContractsList, setCurrentHotlineList } from '../../store/commonReducer';

import SearchSelect from '../../components/SearchSelect';
import Box from '../../components/Box';
import CountryPhCode from '../../components/CountryPhCode';
import { request, error, success } from '../../tools';

import './styles.scss';
import { COUNTRY_PHONE_CODES } from 'common_constants/business';

const findContract = (contractsList, data) => {
  const find = (contract) => contract?._id === data?._id;
  return contractsList?.filter(find)?.[0];
};

const NewContract = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const loading = useSelector((state) => state.common.reqLoader);
  const { data, hotline } = useSelector((state) => state.common.modal);
  const contractsList = useSelector((state) => state.common.contracts);
  const hotlineList = useSelector((state) => state.common.currentHotlineList);
  const currentClient = useSelector((state) => state.common.currentClient);
  const findedContract = findContract(contractsList, data);
  const currentContract = findedContract || data || [];

  const client = data?.client?.[0] || currentClient;
  const { Text } = Typography;

  const [name, setName] = useState(data?.name || hotline?.name || '');
  const [phone, setPhone] = useState(client?.ph || hotline?.phone || '');
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [foundName, setFoundName] = useState('');
  const [countryPhCode, setCountryPhCode] = useState(client?.countryPhCode ?? COUNTRY_PHONE_CODES.UA);

  const [isLoading, setIsLoading] = useState(false);

  const [contractForm, setContractForm] = useState({
    H: currentContract.H || hotline?._id,
    sd: dayjs(currentContract.sd),
    od: currentContract.od ? 1 : 0,
    pa: currentContract.pa,
    cd: currentContract.cd,
    ci: currentContract.ci,
    isNewContract: !(currentContract._id || currentContract.contractId),
    contractId: currentContract._id || currentContract.contractId,
    i: currentContract?.i,
    _id: currentContract._id || '',
    services: currentContract.services || [],
  });

  const onCancel = () => {
    dispatch(setModal());
    dispatch(setCurrentClient());
  };

  const onSubmit = async () => {
    if (contractForm.isNewContract && (!name || !phone)) {
      setErrorText('Будь ласка, заповніть всі поля.');
      return;
    }

    if (!contractForm.sd || !contractForm.ci || !contractForm.cd || !contractForm.pa) {
      setErrorText('Будь ласка, заповніть всі поля.');
      return;
    }

    setIsLoading(true);

    const contract = {
      H: contractForm.H,
      C: client?._id || contractForm.C,
      od: contractForm.od,
      sd: contractForm.sd,
      ci: contractForm.ci,
      cd: contractForm.cd,
      pa: contractForm.pa,
      _id: contractForm?._id,
      phone,
      countryPhCode,
      name,
      services: [{ date: contractForm.sd, price: contractForm.pa, service: 'Перший платіж' }],
    };

    await request.post(
      '/contracts/addPartnerContract',
      { ...contract },
      (res) => {
        if (contractForm.isNewContract) {
          dispatch(setContractsList([res.data, ...contractsList]));
          setPhone('');
        } else {
          const updatedContractsList = contractsList.map((i) => {
            if (i._id === contract._id) {
              const _sd = contract.sd ? { sd: contract.sd.toJSON() } : {};
              return { ...i, ...contract, client: [client], ..._sd };
            }
            return i;
          });

          dispatch(setContractsList(updatedContractsList));
        }

        if (hotline) {
          const _currentHotlineList = hotlineList.map((i) => {
            return i._id === hotline?._id ? { ...i, K: res.data?._id, C: res.data?.C } : i;
          });

          dispatch(setCurrentHotlineList(_currentHotlineList));
        }

        dispatch(setModal());
        success();
      },
      error,
    );

    setIsLoading(false);
  };

  const getClientsName = () => {
    const body = { phone, countryPhCode };
    if (!phone) return;

    request.post(
      '/partnersHotlines/getNameByPhone',
      body,
      (res) => {
        if (res.data?.name) {
          setContractForm({ ...contractForm, C: res.data.id });
          setFoundName(res.data.name);
          if (!hotline) setIsPopoverVisible(true);
        } else {
          setFoundName('');
        }
      },
      error,
    );
  };

  const handleConfirmName = () => {
    setName(foundName);
    setIsPopoverVisible(false);
    setFoundName('');
  };

  const handleCancelPopover = () => {
    setIsPopoverVisible(false);
    setFoundName('');
  };

  useEffect(() => {
    if (hotline) {
      getClientsName();
    }
  }, []);

  if (!users) return null;

  return (
    <Modal
      open
      className="new-contract"
      title={contractForm.isNewContract ? 'Додаємо новий договір' : 'Редагуємо договір'}
      onCancel={onCancel}
      footer={null}
    >
      <Spin tip="Завантаження" spinning={loading || isLoading}>
        {errorText && <Alert message={errorText} type="error" showIcon closable onClose={() => setErrorText('')} />}
        <Box>
          {contractForm.isNewContract ? (
            <>
              <span>Телефон:</span>
              <div style={{ display: 'flex', marginBottom: 16 }}>
                <CountryPhCode value={countryPhCode} onChange={setCountryPhCode} /> &nbsp;
                <Input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onBlur={getClientsName}
                  maxLength={10}
                  placeholder="Введіть номер телефону"
                />
              </div>

              <span>ПІБ клієнта:</span>
              <Popover
                content={
                  <div style={{ width: '80%' }}>
                    <p>
                      Знайдено користувача: <span style={{ fontWeight: 'bold' }}>{foundName}</span>
                    </p>
                    <Button type="primary" onClick={handleConfirmName}>
                      Підтвердити
                    </Button>
                    <Button type="link" onClick={handleCancelPopover}>
                      Відмінити
                    </Button>
                  </div>
                }
                title="Підтвердження"
                trigger="click"
                open={isPopoverVisible}
              >
                <Input placeholder="Введіть ПІБ клієнта" value={name} onChange={(e) => setName(e.target.value)} style={{ marginBottom: 16 }} />
              </Popover>
            </>
          ) : (
            <>
              <Box mt={16} className="client-label">
                <Text strong className="row-space">
                  Клієнт:
                </Text>
                <Text> {client?.n || '-'} </Text>
                {client?.ph && <CheckCircleOutlined style={{ fontSize: 20, fontWeight: 900, color: 'green' }} />}
              </Box>
            </>
          )}
        </Box>
        <Text>Дата підписання</Text>
        <DatePicker locale={locale} value={contractForm.sd} format="DD-MM-YYYY" onChange={(v) => setContractForm({ ...contractForm, sd: v })} />
        <br />
        <br />
        <Text>Офіс/дист</Text>
        <SearchSelect list={['Офіс', 'Дист']} value={contractForm.od} onChange={(v) => setContractForm({ ...contractForm, od: v })} />
        <br />
        <br />
        <Text>Предмет договору</Text>
        <Input aria-label="Предмет договору" value={contractForm.ci} onChange={(e) => setContractForm({ ...contractForm, ci: e.target.value })} />
        <br />
        <br />
        <Text>Примітка</Text>
        <Input aria-label="Своїми словами" value={contractForm.cd} onChange={(e) => setContractForm({ ...contractForm, cd: e.target.value })} />
        <br />
        <br />
        <Text>Гонорар</Text>
        <Input aria-label="Гонорар" value={contractForm.pa} onChange={(e) => setContractForm({ ...contractForm, pa: e.target.value })} />
        <br />
        <Divider />
        <Button type="primary" className="submit" onClick={onSubmit}>
          {contractForm.isNewContract ? 'Додати' : 'Змінити'} договір
        </Button>
      </Spin>
    </Modal>
  );
};

export default NewContract;
