import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin, Modal } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { setCurrentHotlineList, setModal } from '../../store/commonReducer';
import { warn, success, error, request } from '../../tools';

const HotlineMeet = () => {
  const dispatch = useDispatch();

  const modalData = useSelector((state) => state.common.modal.data);
  const list = useSelector((state) => state.common.currentHotlineList);

  const [isLoading, setIsLoading] = useState(false);

  const confirmMeet = (data, val) => {
    const validation = (data, val) => {
      if (!data) {
        warn('', 'Для звітування потрібно обрати чинну зустріч', { data: data });
        return;
      }
      if (!val) {
        warn('', 'Для звітування потрібно нажати на одну із кнопок', { val: val });
        return;
      }
      return true;
    };

    if (!validation(data, val)) return;

    setIsLoading(true);

    request.post(
      '/partnersHotlines/confirmMeet',
      { _id: data._id, s: val },
      () => {
        const _list = list.map((i) => {
          return i._id === data._id ? { ...i, s: val } : i;
        });

        success();
        setIsLoading(false);
        dispatch(setModal());
        dispatch(setCurrentHotlineList(_list));
      },
      () => {
        error();
        setIsLoading(false);
      },
    );
  };

  const handleCloseModal = () => dispatch(setModal());

  return (
    <Modal title="Чи відбулась зустріч?" open onCancel={handleCloseModal} footer={null}>
      <Spin tip="Завантаження" spinning={isLoading}>
        <div className="btn-row">
          <Button className="green" onClick={() => confirmMeet(modalData, '1')}>
            <CheckOutlined />
          </Button>
          <Button type="primary" danger onClick={() => confirmMeet(modalData, '2')}>
            <CloseOutlined />
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};

export default HotlineMeet;
