import { notification } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';
import { CLIENT_COLOR } from 'common_constants/business';
import reactNativeService from './reactNativeService';

const coreConsoleLog = (_note, _title = dayjs().format('T HH:mm:ss Z [Z]')) => {
  console.group(_title);

  if (Array.isArray(_note)) {
    console.table(_note);
  } else if (typeof _note === 'object') {
    if (_note?.response) {
      console.log('Info Status', { status: _note?.response?.status, statusText: _note?.response?.statusText });
      console.table(_note?.response?.data ?? {});
    } else {
      console.table(_note);
    }
  } else {
    console.log(_note);
  }

  console.groupEnd(_title);
};

const warn = (text, description, noteLog) => {
  const bodyNotif = {
    message: text || 'Увага!',
    description: description || 'Щось пішло не так',
    dismiss: {
      duration: 25000,
      onScreen: true,
    },
    ...(noteLog ? { onClose: () => coreConsoleLog(noteLog, 'Notification Closed Warn!') } : {}),
  };

  notification['warning'](bodyNotif);
}; // при неправильних введених даних адвокатом

const success = (text, description, noteLog) => {
  const bodyNotif = {
    message: text || 'Успіх!',
    ...(description ? { description: description } : {}),
    dismiss: {
      duration: 25000,
      onScreen: true,
    },
    ...(noteLog ? { onClose: () => coreConsoleLog(noteLog, 'Notification Closed Success!') } : {}),
  };

  notification['success'](bodyNotif);
};

const error = (text, description, noteLog) => {
  const bodyNotif = {
    message: text || 'Помилка!',
    description: description || 'Щось пішло не так',
    dismiss: {
      duration: 25000,
      onScreen: true,
    },
    ...(noteLog ? { onClose: () => coreConsoleLog(noteLog, 'Notification Closed Error!') } : {}),
  };

  notification['error'](bodyNotif);
}; // при помилках на сервері чи інших error

const info = (text, description, noteLog) => {
  const bodyNotif = {
    message: text || 'Інформація!',
    ...(description ? { description: description } : {}),
    dismiss: {
      duration: 25000,
      onScreen: true,
    },
    ...(noteLog ? { onClose: () => coreConsoleLog(noteLog, 'Notification Closed Info!') } : {}),
  };

  notification['info'](bodyNotif);
};

const requestFile = (url, data, callback, onError) => {
  try {
    const token = window.localStorage.getItem('partnerToken');

    reactNativeService.sendMessageToWebview({
      type: 'requestForDownloadFile',
      data: { url: process.env.REACT_APP_API + url, data: data, token: token },
    });

    axios
      .post(process.env.REACT_APP_API + url, data, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.fileName ?? 'no_name'); //* ім`я файлу треба записувати через body API Fetch ключем fileName
        document.body.appendChild(link);
        link.click();
        callback(response.data);
      })
      .catch((err) => {
        coreConsoleLog(err);
        typeof onError === 'function' ? onError('', '', err) : error('', '', 'Помилка завантаження файлу');
      });
  } catch (err) {
    coreConsoleLog(err);
    typeof onError === 'function' ? onError('', '', err) : error('', '', 'Помилка завантаження файлу');
  }
};

const prepareUsers = (list) => {
  window.users = {};
  const _users = {};
  list.forEach?.((i) => {
    window.users[i.email] = i;
    _users[i._id] = i;
  });
  return _users;
};

const getTokenData = (token) => (token ? JSON.parse(window.atob(token.split('.')[1])) : false);

const formatBytes = (bytes) => {
  if (bytes < 1024) {
    return bytes + ' bytes';
  } else if (bytes < 1048576) {
    return (bytes / 1024).toFixed(2) + ' kb';
  } else {
    return (bytes / 1048576).toFixed(2) + ' mb';
  }
};

const getClientColor = (client) => {
  const background = !client ? '' : CLIENT_COLOR[client.color]?.bg ?? '';
  const color = !client ? 'red' : CLIENT_COLOR[client.color]?.hex ?? '';
  const name = !client ? '' : CLIENT_COLOR[client.color]?.name ?? '';
  const length = Object.keys(CLIENT_COLOR).length;
  return { background, color, name, length };
};

const htmlDecode = (str) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return doc.documentElement.textContent;
};

const request_platform = async (checkType, url, dataDynamic, callback, onError) => {
  try {
    const token = window.localStorage.getItem('partnerToken');
    const headers = { Authorization: `Bearer ${token}` };

    let response;

    switch (checkType) {
      case 'post':
        response = await axios
          .post(`${process.env.REACT_APP_API}${url}`, dataDynamic, {
            headers: headers,
          })
          .catch((err) => {
            throw err;
          });
        break;
      case 'delete':
        response = await axios
          .delete(`${process.env.REACT_APP_API}${url}${dataDynamic}`, {
            headers: headers,
          })
          .catch((err) => {
            throw err;
          });
        break;
      default:
        break;
    }

    if (!response.data?.status) {
      response.data?.logoutSignal && removePartnerToken();
      if (typeof onError === 'function') onError('', '', response.data);
      else error('', response.data.errorMessage, response.data);
      return;
    }
    return callback(response.data);
  } catch (err) {
    coreConsoleLog(err);
    if (typeof onError === 'function') {
      const getTextErr = err?.response?.data?.err,
        getTextError = err?.response?.data?.error;

      const textErrorForDescription = getTextErr || getTextError || '';

      onError('', textErrorForDescription, err);
    }
  }
};

const request = {
  async post(url, data, callback, onError) {
    await request_platform('post', url, data, callback, onError);
  },
  async delete(url, id, callback, onError) {
    await request_platform('delete', url, id, callback, onError);
  },
};

function subtractPercentage(amount, percentage) {
  return amount - amount * (percentage / 100);
}

class RequestPromise {
  constructor(url, transaction, onSuccess, onError) {
    this.promise = new Promise((resolve, reject) => {
      request.post(
        url,
        transaction,
        (req) => {
          if (onSuccess) onSuccess(req);
          resolve(req);
        },
        (err) => {
          if (onError) onError(err);
          reject(err);
        },
      );
    });
  }

  then(onSuccess, onError) {
    return this.promise.then(onSuccess, onError);
  }

  catch(onError) {
    return this.promise.catch(onError);
  }
}

const viewNameUserAndVerify = (item, users = window.users) => {
  const user = users[item];
  const textNotFindUser = `Невідомий користувач - ${item}`;
  const textNotEnoughData = `Не хватає даних по користувачу - ${item}`;

  return user ? user.p || user.name || textNotEnoughData : textNotFindUser;
};

function removePartnerToken() {
  window.localStorage.removeItem('partnerToken');
  window.location.reload();
  return;
}

const sortUsersByRole = (users) => {
  return Object.values(users).sort((a, b) => {
    if (a.role === 'manager' && b.role !== 'manager') {
      return -1;
    }
    if (a.role !== 'manager' && b.role === 'manager') {
      return 1;
    }
    return 0;
  });
};

export {
  request,
  RequestPromise,
  requestFile,
  formatBytes,
  getTokenData,
  warn,
  success,
  error,
  info,
  prepareUsers,
  getClientColor,
  htmlDecode,
  viewNameUserAndVerify,
  sortUsersByRole,
  subtractPercentage,
};
