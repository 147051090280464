import { configureStore } from '@reduxjs/toolkit';

import commonReducer from './commonReducer';
import uiReducer from './uiReducer';

export const store = configureStore(
  {
    reducer: {
      common: commonReducer,
      ui: uiReducer,
    },
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
