import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row, Collapse } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { NEW_CONTRACT } from 'common_constants/modals';
import { ClientInfoRow, ContractInfoTextPart } from './ContractInfoPartials';
import { setCurrentClient, setModal } from '../../../store/commonReducer';

import './styles.scss';
import ContractServices from '../ContractServices/ContractServices';

const { Panel } = Collapse;

const ContractInfo = ({ data }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.common.users);
  const [activeKey, setActiveKey] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const onEdit = () => {
    dispatch(setCurrentClient(data.client[0]));
    dispatch(setModal({ name: NEW_CONTRACT, data, fromContracts: true }));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!data) return 'Відсутня інформація';

  return (
    <Card className="contractInfo">
      <Row gutter={16}>
        <Col style={{ display: 'flex', flexDirection: 'column' }} span={18}>
          <ClientInfoRow data={data} />
        </Col>
        <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className="assignment-item-buttons-inner">
            <Row className="assignment-item-buttons-inner">
              <Col className="assignment-item-buttons">
                <Button type="primary" className="assignment-item__button" onClick={onEdit}>
                  <EditOutlined />
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <div className="divider"></div>

      <Row>
        <Col style={{ display: 'flex', flexDirection: 'column' }}>
          <ContractInfoTextPart data={data} users={users} />
        </Col>
      </Row>

      <ContractServices data={data} />
    </Card>
  );
};

export default ContractInfo;
