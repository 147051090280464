import { Tag } from 'antd';
import { FieldNumberOutlined, UserOutlined } from '@ant-design/icons';

const getColumns = (mob) => {
  const columns = [
    {
      title: <FieldNumberOutlined style={{ color: '#1890ff', fontSize: '20px' }} />,
      key: 'i',
      render: (contract) => (
        <Tag color="geekblue" style={{ fontSize: '14px', padding: '6px 12px' }}>
          {contract.i}
        </Tag>
      ),
    },
    {
      title: <UserOutlined style={{ color: '#1890ff', fontSize: '20px' }} />,
      dataIndex: 'client',
      key: 'client',
      render: (client) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <p style={{ margin: 0, fontSize: '16px', color: '#777' }}>{client[0]?.n ?? '-'}</p>
          </div>
        );
      },
    },
  ];

  return columns;
};

export default getColumns;
