import { ExclamationCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Empty, Modal, Row, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ADD_PARTNERS_HOTLINE } from 'common_constants/modals';
import { setCurrentHotlineList, setModal } from '../../store/commonReducer';
import { error, request, warn } from '../../tools';
import Item from './Item';

import './List.scss';

const { Text } = Typography;

const List = () => {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.common.currentHotlineList);

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(dayjs());

  const updateRecordInList = (updatedRecord) => {
    const updatedList = list.map((item) => (item.phone === updatedRecord.phone ? updatedRecord : item));

    dispatch(setCurrentHotlineList(updatedList));
  };
  const getList = async (date) => {
    const validation = (date) => {
      if (!date) {
        warn('', 'Для списку консультацій потрібно обрати дату', { date: date });
        return false;
      }
      return true;
    };

    if (!validation(date)) return;

    const dateString = date?.toISOString();

    setLoading(true);

    const transaction = {
      date: dateString,
    };

    await request.post(
      '/partnersHotlines/getAllHotlines',
      transaction,
      ({ hotlines }) => {
        dispatch(setCurrentHotlineList(hotlines));
      },
      (error) => {
        console.error('Error in request:', error);
      },
    );

    setLoading(false);
  };

  const removeConfirm = (data) => {
    Modal.confirm({
      title: 'Ви впевнені у видаленні запису?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Так',
      okType: 'danger',
      cancelText: 'Ні',
      onOk() {
        console.log('');
        onRemoveClick(data);
      },
      onCancel() {
        console.log('');
      },
    });
  };

  const onAddSuccess = () => {
    getList(date);
  };

  const onDateChange = (value) => {
    setDate(value);
    getList(value);
  };

  const onEditClick = (data) => {
    dispatch(setModal({ name: ADD_PARTNERS_HOTLINE, data }));
  };

  const onRemoveClick = (data) => {
    const validation = (data) => {
      if (!data._id) {
        warn('', 'Для видалення запису потрібно вказати чинного клієнта', { 'data.id': data.id });
        return;
      }
      return true;
    };

    if (!validation(data)) return;

    setLoading(true);

    const transaction = {
      _id: data._id,
    };

    request.post(
      '/partnersHotlines/remove',
      transaction,
      (req) => {
        req.status && getList(date);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    getList(date);
  }, []);

  return (
    <Spin tip="Завантаження" spinning={loading}>
      <div className="list-page default-page">
        <h2>Записи</h2>
        <Row style={{ justifyContent: 'center' }}>
          <Button type="primary" onClick={() => dispatch(setModal({ name: ADD_PARTNERS_HOTLINE, data: { onAddSuccess } }))}>
            <PlusCircleOutlined /> Додати запис
          </Button>
        </Row>

        <br />
        <div className="minimal-date-picker-container">
          <DatePicker value={date} onChange={onDateChange} format="YYYY-MM-DD" className="minimal-date-picker" allowClear={false} />
        </div>

        {!list?.length ? (
          <Empty description={<Text>Немає записів</Text>} />
        ) : (
          list.map((i, index) => (
            <Item i={i} key={i._id} index={index} onEditClick={onEditClick} removeConfirm={removeConfirm} updateRecord={updateRecordInList} />
          ))
        )}
      </div>
    </Spin>
  );
};

export default List;
