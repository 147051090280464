import { ROUTES } from 'common_constants/routes';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import Auth from './pages/Auth';

import List from './pages/List';
import Profile from './pages/Profile';
import Contracts from './pages/Contracts/Contracts';

export default function RouterSwitch() {
  const userAuth = useSelector((state) => state.common.userAuth);

  return (
    <Switch>
      <Route exact path={ROUTES.AUTH}>
        <Auth />
      </Route>
      {userAuth && (
        <>
          <Route exact path={ROUTES.LIST}>
            <List />
          </Route>
          <Route exact path={ROUTES.PROFILE}>
            <Profile />
          </Route>
          <Route exact path={ROUTES.CONTRACTS}>
            <Contracts />
          </Route>
        </>
      )}
    </Switch>
  );
}
