import React from 'react';
import { COURT_PRACTICE } from 'common_constants/modals';
import { Col, Card, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentAuthUserPersonalDate, setModal } from '../../store/commonReducer';
import { request, error, success, requestFile } from '../../tools';

const CourtPracticeItem = ({ data }) => {
  const dispatch = useDispatch();
  const currentAuthUserPersonalDate = useSelector((state) => state.common.currentAuthUserPersonalDate);

  // const onClickEditHandle = () => {
  //   dispatch(setModal({ name: COURT_PRACTICE, data: data }));
  // };

  const deleteCourtPractice = () => {
    if (!data._id) {
      error('', 'Будь ласка, перезавантажте сторінку і спробуйте знову!');
    }

    request.post(
      '/profile/partnersCourtPractice',
      { fileId: data.fileId, id: data._id },
      () => {
        const updateCourtPractice = currentAuthUserPersonalDate.practice.filter((item) => item._id !== data._id);
        let uppdatedUser = { ...currentAuthUserPersonalDate, practice: updateCourtPractice };
        dispatch(setCurrentAuthUserPersonalDate(uppdatedUser));
      },
      error,
    );
  };

  const onDownloadFile = () => {
    const fileData = {
      fileId: data.fileId,
      fileName: data.fileName,
    };

    requestFile(
      '/profile/courtPractice/downloadFileForPartnerCrm',
      fileData,
      () => {
        success('', 'Документ успішно завантажено');
      },
      (err) => {
        error('', 'Помилка при відправці файлу', err);
      },
    );
  };

  return (
    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
      <Card
        title={data.name}
        actions={[
          <DownloadOutlined onClick={onDownloadFile} key="download" />,
          // <EditOutlined onClick={onClickEditHandle} key="edit" />,
          <Popconfirm
            title="Видалення практики"
            description="Ви впевнені, що хочете видалити дану практику?"
            okText="Так"
            cancelText="Ні"
            key="delete"
            onConfirm={deleteCourtPractice}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>,
        ]}
      >
        {data?.briefly ? (
          <p>
            <b>Коротко:</b> {data.briefly}
          </p>
        ) : null}
        {data.description ? (
          <p>
            <b>Опис:</b> {data.description}
          </p>
        ) : null}
      </Card>
    </Col>
  );
};

export default CourtPracticeItem;
