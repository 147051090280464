import React from 'react';
import { Typography, Row, Col } from 'antd';
import {
  CalendarOutlined,
  HomeOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  MoneyCollectOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const ContractInfoTextPart = ({ data }) => {
  const { Text } = Typography;

  return (
    <Row gutter={[16, 16]} style={{ maxWidth: '600px', margin: '0 auto' }}>
      <Col span={24}>
        <Row align="middle">
          <Col>
            <CalendarOutlined style={{ fontSize: '18px', color: '#1890ff' }} /> <Text strong>Дата підписання:</Text>&nbsp;
          </Col>
          <Col>
            <Text style={{ margin: 0, fontSize: '14px', color: '#777' }}>{dayjs(data.sd)?.format('DD-MM-YYYY') ?? '-'}</Text>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col>
            <HomeOutlined style={{ fontSize: '18px', color: '#1890ff' }} /> <Text strong>Офіс/дист:</Text>&nbsp;
          </Col>
          <Col>
            <Text style={{ margin: 0, fontSize: '14px', color: '#777' }}>{data.od === 1 ? 'Дист' : 'Офіс'}</Text>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col>
            <FileTextOutlined style={{ fontSize: '18px', color: '#1890ff' }} /> <Text strong>Предмет договору:</Text>&nbsp;
          </Col>
          <Col>
            <Text style={{ margin: 0, fontSize: '14px', color: '#777' }}>{data.ci ?? '-'}</Text>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col>
            <UnorderedListOutlined style={{ fontSize: '18px', color: '#1890ff' }} /> <Text strong>Примітка:</Text>&nbsp;
          </Col>
          <Col>
            <Text style={{ margin: 0, fontSize: '14px', color: '#777' }}>{data.cd ?? '-'} </Text>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row align="middle">
          <Col>
            <MoneyCollectOutlined style={{ fontSize: '18px', color: '#1890ff' }} /> <Text strong>Гонорар:</Text>&nbsp;
          </Col>
          <Col>
            <Text style={{ margin: 0, fontSize: '14px', color: '#777' }}>{data.pa ?? '-'}</Text>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContractInfoTextPart;
