import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Card, Collapse, Typography, Spin, Modal } from 'antd';

import { Box } from 'common_components';

import dayjs from 'dayjs';

import { error, request } from '../../tools';
import { setModal } from '../../store/commonReducer';

const HistoryHotlines = () => {
  const [{ Panel }, { Text }] = [Collapse, Typography];

  const dispatch = useDispatch();

  const [hotlines, setHotlines] = useState([]);
  const onCancel = () => dispatch(setModal());
  const { phone, countryPhCode } = useSelector((state) => state.common.modal.data);
  const [loading, setLoading] = useState(false);

  const getHotlineResult = () => {
    request.post(
      '/partnersHotlines/getByPhone',
      { phone: phone, countryPhCode: countryPhCode },
      (res) => {
        const sorted =
          res.data?.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          }) || [];
        setHotlines(sorted);
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    setLoading(true);
    getHotlineResult();
  }, []);

  return (
    <Spin tip="Завантаження" spinning={loading}>
      <Modal className="library-info-list" open title={'Історія консультацій'} onCancel={onCancel} footer={null}>
        <Box>
          <Collapse size="small" accordion defaultActiveKey={['1']}>
            <Panel
              header={`Консультації по вказаному номеру. За місяць: ${
                hotlines.filter((item) => dayjs(item.date).isSame(dayjs(), 'month')).length
              }. Загалом: ${hotlines.length}. `}
              key="1"
              open
            >
              {hotlines.map((item) => (
                <Card size="small" key={item._id}>
                  <Text strong>{dayjs(item.date).format('DD.MM.YYYY')}</Text>
                  <br />
                  <Text strong>{item.name}</Text>
                  <br />
                  <Text strong>
                    +{item.countryPhCode} {item.phone}
                  </Text>
                  <br />
                  <span>
                    <Text strong>Статус:</Text>&nbsp;
                    <Text type={item.s === '1' ? 'success' : 'danger'}>{item.s === '1' ? 'В' : 'Не в'}ідбулась</Text>
                  </span>
                  <br />
                  <Text strong>Ціна:</Text> {item.cost}
                  <br />
                  <Text strong>Коментар:</Text> {item.comment !== '' ? item.comment : '-'}
                </Card>
              ))}
            </Panel>
          </Collapse>
        </Box>
      </Modal>
    </Spin>
  );
};

export default HistoryHotlines;
