import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Menu, Form, Input, Button, Checkbox } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { ROUTES } from 'common_constants/routes';
import { setActualization, setCurrentAuthUserPersonalDate, setUserAuth, setUsers, setAppPrepared } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { request, getTokenData, error, prepareUsers } from '../../tools';

import './Auth.scss';

const Auth = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onFinish = async (data) => {
    loginRequest({
      email: data.email,
      password: data.password,
    });
  };

  const loginRequest = (data) => {
    request.post('/auth/partnerLogin', data, loginReqCallback, error);
  };

  const loginReqCallback = (res) => {
    window.localStorage.setItem('partnerToken', res.partnerToken);
    dispatch(setUserAuth(getTokenData(res.partnerToken)));
    getInitialData();
    history.push(ROUTES.LIST);
  };

  const getInitialData = () => {
    dispatch(setMainLoader(true));
    request.post('/universal/partnerInitial', {}, (res) => {
      const _users = prepareUsers(res.users);
      dispatch(setUsers(_users));
      dispatch(setCurrentAuthUserPersonalDate(res.userPersonalData || {}));
      dispatch(setActualization(res.actualization || {}));
      dispatch(setAppPrepared(true));
      dispatch(setMainLoader(false));
    });
  };

  return (
    <div className="auth_page default_page">
      <Menu
        className="menu"
        // onClick={onTypeClick}
        selectedKeys={['in']}
        mode="horizontal"
      >
        <Menu.Item key="in" icon={<MailOutlined />}>
          Login
        </Menu.Item>
        {/* <Menu.Item key="up" icon={<AppstoreOutlined />}>
        Sign Up
      </Menu.Item> */}
      </Menu>

      <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Email обов`язкове поле' },
            { type: 'email', message: 'Email не корректний!' },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Пароль обов`язкове поле' }]}>
          <Input.Password />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Auth;
