import { UserOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';

const ClientInfoRow = (props) => {
  const { data } = props;
  const { Text } = Typography;
  return (
    <span style={{ margin: 0, fontSize: '14px', color: '#777' }}>
      <UserOutlined style={{ fontSize: '18px', color: '#1890ff' }} /> &nbsp;
      <Text strong>Клієнт:</Text>&nbsp;
      {!data.client[0] ? (
        <span className="error">Помилка</span>
      ) : (
        <>
          +{data.client[0]?.countryPhCode}&nbsp;
          {data.client[0]?.ph} &nbsp;
          <br />
          {data.client[0]?.n} &nbsp;
        </>
      )}
    </span>
  );
};

export default ClientInfoRow;
