import React, { useState } from 'react';
import { COURT_PRACTICE } from 'common_constants/modals';
import { PlusOutlined, MinusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select, Input, Col, Row, Form, Upload, message, Card } from 'antd';

import { success, request, error } from '../../tools';
import { setCurrentAuthUserPersonalDate, setModal } from '../../store/commonReducer';
import AchievementImage from './AchievementImage';
import CourtPracticeItem from './CourtPracticeItem';
import phoneNumberValidator from 'common_components/src/tools/phoneNumberValidator';

import './CurrentUserPersonalInfo.scss';

const { TextArea } = Input;

const options = [
  { value: 'Батько', label: 'Батько' },
  { value: 'Мати', label: 'Мати' },
  { value: 'Брат', label: 'Брат' },
  { value: 'Сестра', label: 'Сестра' },
  { value: 'Хлопець', label: 'Хлопець' },
  { value: 'Дівчина', label: 'Дівчина' },
  { value: 'Чоловік', label: 'Чоловік' },
  { value: 'Дружина', label: 'Дружина' },
  { value: 'Інший знайомий', label: 'Інший знайомий' },
];

const validation = (personalPhone, workPhone, confidantName) => {
  if (!personalPhone) {
    error('', 'Введіть особистий номер телефону.');
    return;
  }

  if (!workPhone) {
    error('', 'Введіть робочий номер телефону.');
    return;
  }

  if (!confidantName) {
    error('', 'Введіть ПІБ Довіреної особи.');
    return;
  }

  return true;
};

const CurrentUserPersonalInfo = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const currentAuthUserPersonalDate = useSelector((state) => state.common.currentAuthUserPersonalDate);
  const userAuth = useSelector((state) => state.common.userAuth);

  const [achievementFileList, setAchievementFileList] = useState([]);
  const [achievementUploading, setAchievementUploading] = useState(false);

  const setUserInfo = (prop, event) => {
    if (prop === 'confidant') {
      dispatch(setCurrentAuthUserPersonalDate({ ...currentAuthUserPersonalDate, ...{ [prop]: event } }));
    } else {
      const propName = prop.toLowerCase();

      if (propName.includes('phone')) {
        const validPhone = phoneNumberValidator(event);
        dispatch(setCurrentAuthUserPersonalDate({ ...currentAuthUserPersonalDate, ...{ [prop]: validPhone } }));
      } else {
        dispatch(setCurrentAuthUserPersonalDate({ ...currentAuthUserPersonalDate, ...{ [prop]: event.target.value } }));
      }
    }
  };

  const handleUpload = () => {
    const formData = new FormData();
    setAchievementUploading(true);

    achievementFileList.forEach((file) => {
      formData.append('file', file);
      formData.append('_id', userAuth._id);
    });
    request.post(
      '/profile/partnersAchievementsEdit',
      formData,
      (res) => {
        setAchievementFileList([]);
        message.success('Фото успішно завантажено');

        const editedUser = {
          ...currentAuthUserPersonalDate,
          achievements: [...currentAuthUserPersonalDate.achievements, { fileId: res.data.fileId }],
        };
        dispatch(setCurrentAuthUserPersonalDate(editedUser));
        setAchievementUploading(false);
      },
      () => {
        message.error('Сталася помилка');
        setAchievementUploading(false);
      },
    );
  };

  const props = {
    onRemove: () => {
      setAchievementFileList([]);
    },
    beforeUpload: (file) => {
      const isJpg = file.type === 'image/jpeg';

      if (!isJpg) {
        message.error('Можливо завантажити фото тільки в JPG форматі!');
        setAchievementFileList([]);
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Розмір файлу повинен бути менше 2 мегабайт!');
        setAchievementFileList([]);
      }
      if (!isJpg || !isLt2M) {
        return false;
      }
      setAchievementFileList([file]);
      return false;
    },
    achievementFileList,
  };

  const onSave = () => {
    if (!validation(currentAuthUserPersonalDate.personalPhone, currentAuthUserPersonalDate.workPhone, currentAuthUserPersonalDate.confidantName))
      return;

    let currentPersonalData = { ...currentAuthUserPersonalDate };
    const formsValue = form.getFieldsValue('');

    for (let item of Object.keys(formsValue)) {
      if (formsValue[item]) {
        dispatch(setCurrentAuthUserPersonalDate({ ...currentAuthUserPersonalDate, ...{ [item]: formsValue[item] } }));
        currentPersonalData = { ...currentPersonalData, ...{ [item]: formsValue[item] } };
      }
    }

    request.post('/profile/updatePartnerPersonalData', currentPersonalData, () => {
      success();
    });
  };

  const onDelete = (value) => {
    if (!validation(currentAuthUserPersonalDate.personalPhone, currentAuthUserPersonalDate.workPhone, currentAuthUserPersonalDate.confidantName))
      return;

    const achievements = currentAuthUserPersonalDate?.achievements.filter((item) => item.fileId !== value);
    let currentPersonalData = { ...currentAuthUserPersonalDate };
    dispatch(setCurrentAuthUserPersonalDate({ ...currentAuthUserPersonalDate, ...{ achievements } }));
    currentPersonalData = { ...currentAuthUserPersonalDate, ...{ achievements } };

    request.post('/profile/updatePartnerPersonalData', currentPersonalData, () => {
      success();
    });
  };

  const onClickAddCourtPractice = () => {
    dispatch(setModal({ name: COURT_PRACTICE }));
  };

  return (
    <div className="currentUserPersonalInfo">
      <Row className="currentUserPersonalInfo__row" gutter={[16, 20]}>
        {/* Name & Contact Section */}
        <Col xs={24} md={12} lg={8}>
          <Card title="Контактні дані" bordered={false}>
            <p>Ім'я:</p>
            <Input value={userAuth.name} disabled />
            <p>Особистий телефон:</p>
            <Input value={currentAuthUserPersonalDate?.personalPhone} placeholder="0950472323" onChange={(e) => setUserInfo('personalPhone', e)} />
            <p>Робочий телефон:</p>
            <Input value={currentAuthUserPersonalDate?.workPhone} placeholder="0950472222" onChange={(e) => setUserInfo('workPhone', e)} />
          </Card>
        </Col>

        {/* Confidant Section */}
        <Col xs={24} md={12} lg={8}>
          <Card title="Довірена особа" bordered={false}>
            <p>Довірена особа:</p>
            <Select
              className="currentUserPersonalInfo__select"
              value={currentAuthUserPersonalDate?.confidant || 'Довірена особа'}
              onChange={(e) => setUserInfo('confidant', e)}
              options={options}
            />
            <p>ПІБ:</p>
            <Input
              value={currentAuthUserPersonalDate.confidantName}
              placeholder="Петренко Артем Дмитрович"
              onChange={(e) => setUserInfo('confidantName', e)}
            />
            <p>Телефон:</p>
            <Input value={currentAuthUserPersonalDate?.confidantPhone} placeholder="0950471111" onChange={(e) => setUserInfo('confidantPhone', e)} />
          </Card>
        </Col>

        {/* Education and Address Section */}
        <Col xs={24} md={12} lg={8}>
          <Card title="Освіта та Адреси" bordered={false}>
            <p>Освіта:</p>
            <Input value={currentAuthUserPersonalDate?.education} placeholder="Вища юридична освіта" onChange={(e) => setUserInfo('education', e)} />
            <p>Адреса реєстрації:</p>
            <Input
              value={currentAuthUserPersonalDate?.registrationAddress}
              placeholder="Україна, Одеська область, Березівський район, смт. Березівка, вул. Петровська, 23, кв. 2"
              onChange={(e) => setUserInfo('registrationAddress', e)}
            />
            <p>Адреса проживання:</p>
            <Input
              value={currentAuthUserPersonalDate?.addressOfResidence}
              placeholder="Україна, Одеська область, Березівський район, смт. Березівка, вул. Івановська, 21, кв. 3"
              onChange={(e) => setUserInfo('addressOfResidence', e)}
            />
            <p>Паспортні дані:</p>
            <Input
              value={currentAuthUserPersonalDate?.passportData}
              placeholder="Паспорт серії ЕН 111223, виданий Березівським РВ ОМУ УМВС України в Одеській області 23.12.2007р."
              onChange={(e) => setUserInfo('passportData', e)}
            />
          </Card>
        </Col>
      </Row>

      {/* Extra Education & Achievements Section */}
      <Row gutter={[16, 20]}>
        <Col xs={24} md={24} lg={24}>
          <Card title="Додаткова освіта" bordered={false} style={{ marginBottom: 32 }}>
            <Form initialValues={currentAuthUserPersonalDate} layout="vertical" form={form}>
              <Form.List
                name="extraEducation"
                initialValues={currentAuthUserPersonalDate}
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(new Error('Please add at least one course'));
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ field, key, name }, index) => (
                      <Card key={key} className="currentUserPersonalInfo__extra-education-item">
                        <MinusCircleOutlined onClick={() => remove(name)} />
                        <Form.Item
                          name={[name, 'name']}
                          label="Назва курсу:"
                          rules={[{ required: true, message: 'Будь ласка, введіть назву курсу!' }]}
                        >
                          <Input placeholder="Юридичний форум правників України" />
                        </Form.Item>
                        <Form.Item name={[name, 'location']} label="Місто, рік проходження:">
                          <Input placeholder="м. Київ, 2023р." />
                        </Form.Item>
                        <Form.Item name={[name, 'result']} label="Результати:">
                          <TextArea placeholder="За результатами форуму була нагороджна грамотою" />
                        </Form.Item>
                      </Card>
                    ))}
                    <Form.Item>
                      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
                        <Button type="dashed" icon={<PlusOutlined />} onClick={() => add()}>
                          Додати
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </div>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          </Card>
        </Col>
      </Row>

      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 32 }}>
        <Button type="primary" onClick={onSave}>
          Зберегти
        </Button>
      </div>

      <Row gutter={[16, 20]}>
        <Col xs={24} md={24} lg={24}>
          {/* Court Practice Section */}
          <Row gutter={[16, 20]}>
            <Row className="currentUserPersonalInfo__court-practice-top" wrap={true} align="center" justify={'space-between'}>
              <p className="currentUserPersonalInfo__form-title">Cудова практика</p>
              <Button onClick={onClickAddCourtPractice} type="primary" icon={<PlusOutlined />}>
                Додати практику
              </Button>
            </Row>
            {currentAuthUserPersonalDate?.practice && currentAuthUserPersonalDate.practice.length > 0 && (
              <Row className="currentUserPersonalInfo__court-practice-wrapper" gutter={[16, 20]}>
                {currentAuthUserPersonalDate?.practice?.map((item, index) => (
                  <CourtPracticeItem data={item} key={index} />
                ))}
              </Row>
            )}
          </Row>
        </Col>

        <Col xs={24} md={24} lg={24}>
          <Card title="Здобутки" bordered={false}>
            <Upload {...props} maxCount={1}>
              <Button icon={<UploadOutlined />}>Загрузити</Button>
            </Upload>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={achievementFileList.length === 0}
              loading={achievementUploading}
              style={{ marginTop: 24 }}
            >
              {achievementUploading ? 'Завантаження...' : 'Завантажити'}
            </Button>
            <div className="currentUserPersonalInfo__achievements-wrapper">
              <Row gutter={[16, 16]}>
                {currentAuthUserPersonalDate?.achievements?.map((item, index) => (
                  <AchievementImage item={item} key={index} onDelete={onDelete} />
                ))}
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CurrentUserPersonalInfo;
